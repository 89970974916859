import $fU9C9$cryptojslibtypedarrays from "crypto-js/lib-typedarrays";
import $fU9C9$cryptojssha256 from "crypto-js/sha256";
import $fU9C9$cryptojsencbase64url from "crypto-js/enc-base64url";




/**
 * Thanks to @SEIAROTg on stackoverflow:
 * "Convert a 32bit integer into 4 bytes of data in javascript"
 * @param num The 32bit integer
 * @returns An ArrayBuffer representing 4 bytes of binary data
 */ function $d415641d0cfd8c85$var$toBytesInt32(num) {
    const arr = new ArrayBuffer(4); // an Int32 takes 4 bytes
    const view = new DataView(arr);
    view.setUint32(0, num, false); // byteOffset = 0; litteEndian = false
    return arr;
}
/**
 * Creates an array of length `size` of random bytes
 * @param size
 * @returns Array of random ints (0 to 255)
 */ function $d415641d0cfd8c85$var$getRandomValues(size) {
    const randoms = $fU9C9$cryptojslibtypedarrays.random(size);
    const randoms1byte = [];
    randoms.words.forEach((word)=>{
        const arr = $d415641d0cfd8c85$var$toBytesInt32(word);
        const fourByteWord = new Uint8Array(arr);
        for(let i = 0; i < 4; i++)randoms1byte.push(fourByteWord[i]);
    });
    return randoms1byte;
}
/** Generate cryptographically strong random string
 * @param size The desired length of the string
 * @returns The random string
 */ function $d415641d0cfd8c85$var$random(size) {
    const mask = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-._~";
    let result = "";
    const randomUints = $d415641d0cfd8c85$var$getRandomValues(size);
    for(let i = 0; i < size; i++){
        // cap the value of the randomIndex to mask.length - 1
        const randomIndex = randomUints[i] % mask.length;
        result += mask[randomIndex];
    }
    return result;
}
/** Generate a PKCE challenge verifier
 * @param length Length of the verifier
 * @returns A random verifier `length` characters long
 */ function $d415641d0cfd8c85$var$generateVerifier(length) {
    return $d415641d0cfd8c85$var$random(length);
}
function $d415641d0cfd8c85$export$6a61b14641fe7480(code_verifier) {
    return $fU9C9$cryptojssha256(code_verifier).toString($fU9C9$cryptojsencbase64url);
}
function $d415641d0cfd8c85$export$2e2bcd8739ae039(length) {
    if (!length) length = 43;
    if (length < 43 || length > 128) throw `Expected a length between 43 and 128. Received ${length}.`;
    const verifier = $d415641d0cfd8c85$var$generateVerifier(length);
    const challenge = $d415641d0cfd8c85$export$6a61b14641fe7480(verifier);
    return {
        code_verifier: verifier,
        code_challenge: challenge
    };
}
function $d415641d0cfd8c85$export$4faf58fdf1185e54(code_verifier, expectedChallenge) {
    const actualChallenge = $d415641d0cfd8c85$export$6a61b14641fe7480(code_verifier);
    return actualChallenge === expectedChallenge;
}


export {$d415641d0cfd8c85$export$6a61b14641fe7480 as generateChallenge, $d415641d0cfd8c85$export$2e2bcd8739ae039 as default, $d415641d0cfd8c85$export$4faf58fdf1185e54 as verifyChallenge};
